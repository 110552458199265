import { useState, useEffect } from "react";
import "../BurgerMenu/BurgerMenu.css";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import OutsideClickHandler from "react-outside-click-handler";
import NotificationBar from "../NotificationBar/NotificationBar";
import {
  setActiveTab,
  clearLoginUser,
  setPostJobMode,
  setPostJobId,
  setLoginModel,
  setResumeDetail,
  setInboxCount,
} from "../../../redux/slices/AuthSlice";
import { setTotalBulkResumeCredit } from "../../../redux/slices/buyBulkAnalysisSlice";
import {
  setActiveSideBar,
  setdefaultPermissions,
} from "../../../redux/slices/AdminDashboardSlice";
import useAblyHook from "../../../utils/useAblyHook";
import { setInboxUser } from "../../../redux/slices/InboxSlice";
import {
  getAvailableJobPostsDataAsync,
  getProductPricesDataAsync,
  purchaseJObDataAsync,
  setBuyJobsModelModel,
} from "../../../redux/slices/JobDecisionSlice";
import capitalizeFirstLetter from "../../../utils/capitalizeFirstLetter";
import { ADMIN, CANDIDATE } from "../../../constants";
import JobPurchasedModel from "../../Common/JobPurchasedModel/JobPurchasedModel";
import ContentModal from "../../Common/ContentModal/ContentModal";
import ConfettiModal from "../../Common/ConfettiModal/ConfettiModal";
import "./TopNavBar.css";

const TopNavBar = ({
  findJobHide,
  loginHide,
  signUpHide,
  toggleAuthHeader,
  isLogged,
  setContactModal,
}) => {
  const { isEmailVerify, user, userName, inboxCount, notifyCount } =
    useSelector((state) => state.auth);
  const { buyJobsModel } = useSelector((state) => state.jobDecision);

  const selectedInboxUser = useSelector(
    (state) => state.Inbox?.selectedInboxUser || {}
  );

  const { countryISOCode } = useSelector((state) => state.home);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  // Use the custom hook for Ably
  const { channel, error } = useAblyHook(`message-notification.${user?.id}`);

  const scroll = "search";
  const [openBurger, setOpenBurger] = useState(false);
  const [notifyDropDown, setNotifyDropDown] = useState(false);

  /* Buy Job */
  const [productPrice, setProductPrice] = useState("");
  const [jobPurchased, setJobPurchased] = useState(false);
  const [buyJobsQuantity, setBuyJobsQuantity] = useState(1);
  const [jobPurchasedMore, setJobPurchasedMore] = useState("");

  const onSignUpHandler = () => {
    dispatch(clearLoginUser());
    dispatch(setActiveTab("candidate"));
    dispatch(setPostJobId(null));
  };

  const onLogOutHandler = () => {
    dispatch(clearLoginUser());
    dispatch(setResumeDetail({ resumeId: null, resumeType: 1 }));
    dispatch(setdefaultPermissions({}));
    dispatch(
      setActiveSideBar({
        id: "1",
        name: "Modules",
        value: "Modules",
      })
    );
    dispatch(setInboxUser({}));
    dispatch(setTotalBulkResumeCredit(0));
    localStorage.removeItem("token");
  };

  const onCustomResumeHandler = () => {
    navigate("/candidate-resume-decision");
  };

  const pricingHandler = () => {
    navigate("/pricing-content");
  };

  const postJobClickHanlder = async () => {
    if (isEmailVerify === false) {
      dispatch(setLoginModel(true));
    } else {
      dispatch(getAvailableJobPostsDataAsync())
        .then((response) => {
          const res = response?.payload;
          if (res?.result === true) {
            if (
              res?.availableJobPosts === 0 ||
              res?.availableJobPosts === null
            ) {
              dispatch(setBuyJobsModelModel(true));
              getProductPrice();
            } else {
              dispatch(setActiveTab("employer"));
              dispatch(setPostJobMode("add"));
              dispatch(setPostJobId(null));
              navigate("/post-job");
            }
          }
        })
        .catch((error) => {
          dispatch(setBuyJobsModelModel(false));
          console.log(error);
        });
    }
  };

  const howItWorksHandler = () => {
    navigate("/");
    setTimeout(() => {
      const section = document.getElementById("how_it_work");
      section.scrollIntoView({ behavior: "smooth" });
    }, 1200);
  };

  const onInboxNav = () => {
    if (ADMIN.includes(user?.user_type)) {
      navigate("/employer-inbox");
    } else {
      navigate("/candidate-inbox");
    }
    setNotifyDropDown(false);
  };

  const onProfileNav = () => {
    if (ADMIN.includes(user?.user_type)) {
      navigate("/employer-profile");
    } else {
      navigate("/candidate-profile");
    }
    setNotifyDropDown(false);
  };

  const MainMenuBar = ({
    mobileMenu,
    mobileMenuMethod,
    handleActiveOption,
  }) => {
    const [activePage, setActivePage] = useState("");
    useEffect(() => {
      const baseUrl = window.location.origin;

      // Get the part of the URL after the base URL
      const urlPartAfterBaseUrl = window.location.href.substring(
        baseUrl.length
      );
      setActivePage(urlPartAfterBaseUrl);
      // Log or use the result as needed
    }, []);

    return (
      <div className={`menu-items-poly ${mobileMenu ? "openNavbar" : ""}`}>
        <button className="btn close-menu" onClick={mobileMenuMethod}>
          <img src="/image/cross-btn.png" alt="" />
        </button>
        <div className="profile-section">
          <div className="profile-avatar">
            <img
              src={user?.avatar ? user?.avatar : "/image/default-avatar.png"}
              className="loggedout-prof"
              alt=""
            />
          </div>
          {user ? (
            <h3 className="signIn-btns">
              {user?.first_name} {user?.last_name}
            </h3>
          ) : (
            <h3 className="signIn-btns">
              <Link to="/login">Login/Signup</Link>
            </h3>
          )}
        </div>
        <ul className="menu-list">
          <div className="d-md-none d-block w-100">
            {ADMIN.includes(user?.user_type) ? (
              <>
                <div
                  className={`menu-option-div ${
                    (activePage === "/candidate-dashboard" ||
                      activePage === "/employer-dashboard") &&
                    "active-option"
                  }`}
                  onClick={() => {
                    handleActiveOption("dashboard");
                  }}
                >
                  <img
                    src="/image/dash-m.png"
                    alt="dashboard-icon"
                    className="dashboard-icon"
                  />
                  <h3 className="option-name">Dashboard</h3>
                </div>

                <div
                  className={`menu-option-div ${
                    (activePage === "/candidate-profile" ||
                      activePage === "/employer-profile") &&
                    "active-option"
                  }`}
                  onClick={() => {
                    handleActiveOption("profile");
                  }}
                >
                  <img
                    src="/image/profile-m.png"
                    alt="dashboard-icon"
                    className="dashboard-icon"
                  />
                  <h3 className="option-name">My profile</h3>
                </div>

                <div
                  className={`menu-option-div ${
                    (activePage === "/candidate-inbox" ||
                      activePage === "/employer-inbox") &&
                    "active-option"
                  }`}
                  onClick={() => {
                    handleActiveOption("inbox");
                  }}
                >
                  <img
                    src="/image/inbox-m.png"
                    alt="dashboard-icon"
                    className="dashboard-icon"
                  />
                  <h3 className="option-name">Inbox</h3>
                </div>
              </>
            ) : (
              <>
                <div
                  className={`menu-option-div ${
                    (activePage === "/candidate-dashboard" ||
                      activePage === "/employer-dashboard") &&
                    "active-option"
                  }`}
                  onClick={() => {
                    handleActiveOption("dashboard");
                  }}
                >
                  <img
                    src="/image/dash-m.png"
                    alt="dashboard-icon"
                    className="dashboard-icon"
                  />
                  <h3 className="option-name">Dashboard</h3>
                </div>
                <div
                  className={`menu-option-div ${
                    (activePage === "/candidate-profile" ||
                      activePage === "/employer-profile") &&
                    "active-option"
                  }`}
                  onClick={() => {
                    handleActiveOption("profile");
                  }}
                >
                  <img
                    src="/image/profile-m.png"
                    alt="dashboard-icon"
                    className="dashboard-icon"
                  />
                  <h3 className="option-name">My profile</h3>
                </div>
              </>
            )}

            {ADMIN.includes(user?.user_type) ? (
              <></>
            ) : (
              <div
                className={`menu-option-div ${
                  activePage === "/candidate-wishlist" && "active-option"
                }`}
                onClick={() => {
                  handleActiveOption("wishlist");
                }}
              >
                <img
                  src="/image/wishlist-m.png"
                  alt="dashboard-icon"
                  className="dashboard-icon wish-icon"
                />
                <h3 className="option-name">Wishlist</h3>
              </div>
            )}
          </div>
          {!CANDIDATE.includes(user?.user_type) && (
            <li>
              <div className="menu-dropdown-head">
                <div
                  id="dropdown-boxId2"
                  className="company-form-dropdownBox1"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <div className="text-menu-icon">
                    <p>
                      <img src="/image/menuIcon/employers.png" alt="" />
                      Employers
                    </p>
                  </div>
                </div>
                <div className="list-men-dropdown-head">
                  <ul className="menu-item-drop-poly">
                    <li onClick={() => postJobClickHanlder()}>Post a job</li>
                    <li onClick={() => howItWorksHandler()}>How it works</li>
                    {user?.user_type !== "company_user" && (
                      <li onClick={() => pricingHandler()}>Pricing</li>
                    )}
                  </ul>
                </div>
                <div className="list-men-dropdown-box menu-drp-sec">
                  <div className="left-content-menu">
                    <div className="heading-menu-text">
                      <p
                        className="menu-drp-heading icon-cursor"
                        onClick={() => postJobClickHanlder()}
                      >
                        Sign in & <span>Post a Job</span> for free
                      </p>
                      <p className="menu-drp-text">
                        Find top talent quickly, no cost to start.
                      </p>
                    </div>
                    <button
                      className="using-free-btn"
                      onClick={() => postJobClickHanlder()}
                    >
                      Start using for free
                    </button>
                  </div>
                  <div className="right-content-menu">
                    <div
                      className="heading-menu-text headig-bg-clr icon-cursor"
                      onClick={() => howItWorksHandler()}
                    >
                      <p className="menu-drp-heading">How it works</p>
                      <p className="menu-drp-text">
                        See how easy it is to connect with candidates.
                      </p>
                    </div>
                    {user?.user_type !== "company_user" && (
                      <div
                        className="heading-menu-text headig-bg-clr icon-cursor"
                        onClick={() => pricingHandler()}
                      >
                        <p className="menu-drp-heading">Pricing</p>
                        <p className="menu-drp-text">
                          Explore flexible plans to fit your hiring needs.
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </li>
          )}
          {!ADMIN.includes(user?.user_type) && (
            <>
              <li>
                <div className="menu-dropdown-head">
                  <div
                    id="dropdown-boxId2"
                    className="company-form-dropdownBox1"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <div className="text-menu-icon">
                      <p>
                        <img src="/image/menuIcon/jobSeeker.png" alt="" />
                        Jobs
                      </p>
                    </div>
                  </div>
                  <div className="list-men-dropdown-head">
                    <ul className="menu-item-drop-poly">
                      <li
                        onClick={() => {
                          navigate(`/job-search/${scroll}`);
                        }}
                        className="icon-cursor"
                      >
                        Find a job
                      </li>
                    </ul>
                  </div>
                  <div className="list-men-dropdown-box menu-drp-sec">
                    <div className="left-content-menu">
                      <div className="heading-menu-text">
                        <p
                          className="menu-drp-heading"
                          onClick={() => {
                            navigate(`/job-search/${scroll}`);
                          }}
                        >
                          <span>Find</span> your next opportunity
                        </p>
                        <p className="menu-drp-text">
                          Search all available jobs and discover your perfect
                          fit.
                        </p>
                      </div>
                      <button
                        className="using-free-btn"
                        onClick={() => {
                          navigate(`/job-search/${scroll}`);
                        }}
                      >
                        Search jobs
                      </button>
                    </div>
                    <div className="right-content-menu">
                      <img src="/image/search-top.png" alt="" />
                    </div>
                  </div>
                </div>
              </li>             
              <li>
                <div className="menu-dropdown-head">
                  <div className="text-menu-icon"   onClick={() => onCustomResumeHandler()}>
                    <p className="active-menu">                     
                      <img src="/image/menuIcon/resume.png" alt="" />
                      Resume tools
                    </p>
                  </div>
                 <div className="list-men-dropdown-box menu-drp-sec">
                    <div className="left-content-menu">
                      <div className="heading-menu-text">
                        <p
                          className="menu-drp-heading"
                          onClick={() => onCustomResumeHandler()}
                        >
                          <span className="icon-cursor">Build a resume</span>{" "}
                          that gets noticed for free.
                        </p>
                        <p className="menu-drp-text">
                          Use AI to improve your resume, tailor it to specific
                          jobs, or start fresh and build a new one from scratch.
                        </p>
                      </div>
                      <button
                        className="using-free-btn icon-cursor"
                        onClick={() => onCustomResumeHandler()}
                      >
                        Start using for free
                      </button>
                    </div>
                    <div className="right-content-menu">
                      <img src="/image/robot.png" alt="" />
                    </div>
                  </div>
                </div>
              </li>
            </>
          )}

          {user && (
            <li
              className="icon-cursor d-md-none d-block"
              onClick={() => handleActiveOption("logout")}
            >
              <a href="#">
                <img src="/image/menuIcon/logout.png" alt="" />
                Logout
              </a>
            </li>
          )}
        </ul>
        {isEmailVerify === true && ADMIN.includes(user?.user_type) && (
          <div
            className="find-job-btn-candidate-dashbord"
            onClick={() => postJobClickHanlder()}
          >
            <Link>
              <button>Post a job</button>
            </Link>
          </div>
        )}
      </div>
    );
  };

  const PublicHeader = () => {
    const [mobileMenu, setMobileMenu] = useState(false);
    const handleToggleBtn = () => {
      setMobileMenu(!mobileMenu);
      // if (isEmailVerify === false) {
      //   dispatch(setLoginModel(true));
      // }
    };
    return (
      <div className="container container-site">
        <div className="row-log">
          <div className="logo-col icon-cursor log-div">
            <Link to="/">
              <img
                src="/image/polycareers-north-macedonia.png"
                className="logo-mbl"
                alt="logo"
              />
            </Link>
            <p className="country-name-logo">{countryISOCode}</p>
          </div>
          <MainMenuBar
            mobileMenu={mobileMenu}
            mobileMenuMethod={handleToggleBtn}
          />
          <div className="mbl-logo">
            <Link to="/">
              <img
                src="/image/polycareers-north-macedonia.png"
                className="logo-mbl"
                alt="logo"
              />
            </Link>
            <p className="country-name-logo">{countryISOCode}</p>
          </div>
          <div className="col-two-button">
            <div className="menu-items">
              {!loginHide && (
                <p>
                  <Link to="/login">
                    <button className="login-header-poly">Sign in</button>
                  </Link>
                </p>
              )}

              {!signUpHide && (
                <Link to="/signup" onClick={() => onSignUpHandler()}>
                  <button className="signup-poly">Sign up</button>
                </Link>
              )}
            </div>
            <div
              className="toggleBtn"
              onClick={() => {
                handleToggleBtn();
              }}
            >
              <img src="/image/toogle-btn.png" alt="toggleBtn" />
            </div>
          </div>
        </div>
        {/* {openBurger && <BurgerMenu from='public' openBurger={openBurger} setOpenBurger={setOpenBurger}/> } */}
      </div>
    );
  };

  const DashboardHeader = () => {
    const navigate = useNavigate();
    const [hideMenu, setHideMenu] = useState(true);
    const [activeOption, setActiveOption] = useState("");
    const [mobileMenu, setMobileMenu] = useState(false);

    const handleToggleBtn = () => {
      setMobileMenu(!mobileMenu);
    };

    const handleActiveOption = async (option) => {
      setActiveOption(option);
      setOpenBurger(false);
      if (isEmailVerify === true && ADMIN.includes(user?.user_type)) {
        if (option === "dashboard") {
          navigate("/employer-dashboard");
        } else if (option === "profile") {
          navigate("/employer-profile");
        } else if (option === "inbox") {
          navigate("/employer-inbox");
        } else if (option === "settings") {
          navigate("/admin-department");
        } else if (option === "logout") {
          onLogOutHandler();
          navigate("/");
        } else if (option === "password") {
          navigate("/change-password");
        } else if (option === "postJob") {
          await postJobClickHanlder();
        }
      } else {
        if (option === "dashboard") {
          navigate("/candidate-dashboard");
        } else if (option === "profile") {
          navigate("/candidate-profile");
        } else if (option === "inbox") {
          navigate("/candidate-inbox");
        } else if (option === "wishlist") {
          navigate("/candidate-wishlist");
        } else if (option === "logout") {
          onLogOutHandler();
          navigate("/");
        } else if (option === "findJob") {
          navigate(`/job-search/${scroll}`);
        }
      }
    };

    useEffect(() => {
      if (channel) {
        const handleChannelMessage = (message) => {
          if (message?.data?.data) {
            dispatch(setInboxCount(message?.data?.data));
          }
        };
        channel.subscribe("new-notification", handleChannelMessage);

        return () => {
          channel.unsubscribe("new-notification", handleChannelMessage);
        };
      }
    }, [channel]);

    // console.log("message counter", messageCounter);

    return (
      <div className="container container-site-profile">
        {isEmailVerify === true && ADMIN.includes(user?.user_type) ? (
          <>
            <div className="row-log">
              <div className="logo-col icon-cursor">
                <Link to="/">
                  <img
                    src="/image/polycareers-north-macedonia.png"
                    alt="logo"
                  />
                </Link>
                <p className="country-name-logo">{countryISOCode}</p>
              </div>
              <MainMenuBar
                mobileMenu={mobileMenu}
                mobileMenuMethod={handleToggleBtn}
                handleActiveOption={handleActiveOption}
              />
              <div className="col-two-button">
                <div className="hide-wishlist-style">
                  <div className="candidate-name-img icon-cursor">
                    <div className="candidate-wishlist-block icon-cursor">
                      <a onClick={() => onInboxNav()}>
                        <img src="/image/message.png" alt="" />
                        {inboxCount > 0 && (
                          <div className="whislist-notofication">
                            <p className="conter-text-notification">
                              {" "}
                              {inboxCount}
                            </p>
                          </div>
                        )}
                      </a>
                    </div>
                    <img
                      src="/image/profile.png"
                      alt=""
                      onClick={() => onProfileNav()}
                    />
                    <p className="candidate-name">
                      Hi,{" "}
                      {userName !== ""
                        ? capitalizeFirstLetter(userName)
                        : "John"}
                    </p>
                  </div>
                  <div className="toggle-icon-profile icon-cursor toggle-btn-res">
                    <OutsideClickHandler
                      onOutsideClick={() => {
                        setHideMenu(true);
                      }}
                    >
                      <img
                        src="/image/toogle-btn.png"
                        alt=""
                        onClick={() => setHideMenu(!hideMenu)}
                      />
                    </OutsideClickHandler>
                  </div>
                  <div className="toggle-icon-profile icon-cursor toggle-btn-mbl">
                    <OutsideClickHandler
                      onOutsideClick={() => {
                        setHideMenu(true);
                      }}
                    >
                      <img
                        src="/image/toogle-btn.png"
                        alt=""
                        onClick={() => {
                          handleToggleBtn(true);
                        }}
                      />
                    </OutsideClickHandler>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`dropdown-dashbord ${hideMenu && "hide-show-click"}`}
              onClick={() => {
                setHideMenu(true);
              }}
            >
              <ul className="dropdown-menu-dashbord">
                <Link to="/employer-dashboard">
                  <li className="menu-dropdown">
                    <img src="/image/dash-m.png" alt="" />
                    Dashboard
                  </li>
                </Link>
                <Link to="/employer-profile">
                  <li className="menu-dropdown">
                    <img src="/image/profile-m.png" alt="" />
                    My profile
                  </li>
                </Link>
                <Link to="/employer-inbox">
                  <li className="menu-dropdown">
                    <img src="/image/inbox-m.png" alt="" />
                    Inbox
                  </li>
                </Link>
                <Link
                  to="/admin-department"
                  onClick={() =>
                    dispatch(
                      setActiveSideBar({
                        id: "2",
                        name: "Department",
                        value: "Department",
                      })
                    )
                  }
                >
                  <li className="menu-dropdown">
                    <img src="/image/setting-m.png" alt="" />
                    System hub
                  </li>
                </Link>
                <Link to="/" onClick={() => onLogOutHandler()}>
                  <li className="menu-dropdown">
                    <img src="/image/log-m.png" alt="" />
                    Logout
                  </li>
                </Link>
              </ul>
            </div>
          </>
        ) : (
          <>
            <div className="row-log">
              <div className="logo-col icon-cursor">
                <Link to="/">
                  <img
                    src="/image/polycareers-north-macedonia.png"
                    alt="logo"
                  />
                </Link>
                <p className="country-name-logo">{countryISOCode}</p>
              </div>
              <MainMenuBar
                mobileMenu={mobileMenu}
                mobileMenuMethod={handleToggleBtn}
                handleActiveOption={handleActiveOption}
              />
              <div className="profile-right-col icon-cursor">
                <div className="candidate-name-img">
                  <div className="candidate-wishlist-block icon-cursor">
                    <a onClick={() => onInboxNav()}>
                      <img src="/image/message.png" alt="" />
                      {inboxCount > 0 && (
                        <div className="whislist-notofication">
                          <p className="conter-text-notification">
                            {" "}
                            {inboxCount}
                          </p>
                        </div>
                      )}
                    </a>
                  </div>{" "}
                  <NotificationBar
                    notifyDropDown={notifyDropDown}
                    setNotifyDropDown={setNotifyDropDown}
                  />
                  <img
                    src="/image/profile.png"
                    alt=""
                    onClick={() => onProfileNav()}
                  />
                  <p className="candidate-name">
                    Hi,{" "}
                    {userName !== "" ? capitalizeFirstLetter(userName) : "John"}
                  </p>
                </div>
                <div className="toggle-icon-profile icon-cursor toggle-btn-res">
                  <OutsideClickHandler
                    onOutsideClick={() => {
                      setHideMenu(true);
                    }}
                  >
                    <img
                      src="/image/toogle-btn.png"
                      alt=""
                      onClick={() => setHideMenu(!hideMenu)}
                    />
                  </OutsideClickHandler>
                </div>
                <div className="toggle-icon-profile icon-cursor toggle-btn-mbl">
                  <OutsideClickHandler
                    onOutsideClick={() => {
                      setHideMenu(true);
                    }}
                  >
                    <img
                      src="/image/toogle-btn.png"
                      alt=""
                      onClick={() => {
                        handleToggleBtn(true);
                      }}
                    />
                  </OutsideClickHandler>
                </div>
              </div>
            </div>
            <div
              className={`dropdown-dashbord ${hideMenu && "hide-show-click"}`}
              onClick={() => setHideMenu(true)}
            >
              <ul className="dropdown-menu-dashbord">
                <Link to="/candidate-dashboard">
                  <li className="menu-dropdown">
                    <img src="/image/dash-m.png" alt="" />
                    Dashboard
                  </li>
                </Link>
                <Link to="/candidate-profile">
                  <li className="menu-dropdown">
                    <img src="/image/profile-m.png" alt="" />
                    My profile
                  </li>
                </Link>
                <Link to="/candidate-wishlist">
                  <li className="menu-dropdown">
                    <img src="/image/wishlist-m.png" alt="" />
                    Wishlist
                  </li>
                </Link>
                <Link to="/candidate-inbox">
                  <li className="menu-dropdown">
                    <img src="/image/inbox-m.png" alt="" />
                    Inbox
                  </li>
                </Link>
                <Link to="/" onClick={() => onLogOutHandler()}>
                  <li className="menu-dropdown">
                    <img src="/image/log-m.png" alt="" />
                    Logout
                  </li>
                </Link>
              </ul>
            </div>
          </>
        )}
      </div>
    );
  };

  /* Buy Job*/
  const handleJobModel = () => {
    dispatch(setBuyJobsModelModel(!buyJobsModel));
  };

  const handlePurchasedJobs = () => {
    initializeCheckout();
  };

  const handleJobConfirmationModel = () => {
    setJobPurchased(!jobPurchased);
    navigate("/employer-dashboard");
  };

  const initializeCheckout = async () => {
    dispatch(
      purchaseJObDataAsync({
        user_id: user?.id,
        product_name: "purchase_job",
        unit_amount: productPrice?.product?.price,
        quantity: buyJobsQuantity,
      })
    )
      .then((response) => {
        const res = response?.payload;
        if (res?.result === true) {
          const url = res?.checkout_url;
          const newWindow = window.open(url, "_self", "noopener,noreferrer");
          if (newWindow) {
            newWindow.opener = null; // Prevent new tab from having access to current tab
            newWindow.location.href = url; // Navigate the new tab to the specified URL
          } else {
            // Fallback if opening in new tab fails
            navigate(url); // Navigate within the current tab if new tab failed to open
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getProductPrice = async () => {
    dispatch(getProductPricesDataAsync())
      .then((response) => {
        const res = response?.payload;
        if (res?.result === true) {
          setProductPrice(res);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const paramValue = queryParams.get("paymentStatus");
    setJobPurchasedMore(paramValue);
    if (paramValue) {
      dispatch(setBuyJobsModelModel(false));
      setJobPurchased(true);
    }
  }, []);

  return (
    <>
      <header>
        {isEmailVerify === false ? <PublicHeader /> : <DashboardHeader />}
      </header>
      {buyJobsModel && (
        <JobPurchasedModel
          handleJobModel={handleJobModel}
          productPrice={productPrice}
          setBuyJobsQuantity={setBuyJobsQuantity}
          handlePurchasedJobs={handlePurchasedJobs}
        />
      )}

      {jobPurchased && (
        <ContentModal closeModal={handleJobConfirmationModel}>
          <ConfettiModal
            closeModal={handleJobConfirmationModel}
            title={
              jobPurchasedMore === "paid" ? (
                <p>You've successfully subscribed.</p>
              ) : (
                <p>You've successfully purchased new jobs.</p>
              )
            }
          />
        </ContentModal>
      )}
    </>
  );
};

export default TopNavBar;
