import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import OutsideClickHandler from "react-outside-click-handler";
import InvitePeopleModal from "../../InvitePeopleModal/InvitePeopleModal";
import ActionModal from "../../ActionModal/ActionModal";
import commentDate from "../../../../utils/commentDate";
import {
  addMemberConversationDataAsync,
  fetchEmployersInboxDataAsync,
  setInboxUser,
} from "../../../../redux/slices/InboxSlice";
import { CANDIDATE, EMPLOYER, USER_TYPE } from "../../../../constants";
import { toast } from "react-toastify";

const ConversationCard = ({
  prod,
  populateChat,
  onselectMessageUser,
  onMaskReadClickHandler,
  pinUnpinClickHandler,
  getInboxAllUser,
  onDeleteInboxConClickHandler,
}) => {
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);

  const selectedInboxUser = useSelector(
    (state) => state.Inbox?.selectedInboxUser || {}
  );

  const userTypeEmp = EMPLOYER.includes(user?.user_type);
  const userTypeCan = CANDIDATE.includes(user?.user_type);

  const [showMenu, setShowMenu] = useState(false);

  const [deleteChat, setDeleteChat] = useState(false);
  const [deleteChatData, setDeleteChatData] = useState(null);

  const [toggleAddMemberModel, settoggleAddMemberModel] = useState(false);
  const [allCompanyUser, setAllCompanyUser] = useState([]);
  const [selectCompanyUser, setSelectCompanyUser] = useState([]);
  const [allCompanyUserHide, setCompanyUserHide] = useState(true);
  const [userName, setUserName] = useState("");

  const [counter, setCounter] = useState(2);

  const formik = useFormik({
    initialValues: {
      campanyUser: "",
    },
    /*validationscheme*/
    validationSchema: Yup.object({
      campanyUser: Yup.mixed().test(
        "campanyUser",
        "Please select the people to add members list",
        (value) => !!Array.isArray(selectCompanyUser)
      ),
    }),
    onSubmit: async (data, { resetForm }) => {
      let selectCompanyUsersId = selectCompanyUser?.map((item) => item.id);
      if (selectCompanyUsersId?.length < 1) {
        selectCompanyUsersId = [""];
      }
      AddMemberToConversation(selectCompanyUsersId);
    },
  });

  const showSubMenu = () => {
    setShowMenu(!showMenu);
  };

  const closeModal = () => {
    setDeleteChat(!deleteChat);
  };

  const dialogDelete = (prod) => {
    closeModal();
    setDeleteChatData(prod);
  };

  const onPinClickHandler = (prod) => {
    pinUnpinClickHandler(prod?.id);
    setShowMenu(false);
  };

  const deleteComment = async () => {
    onDeleteInboxConClickHandler(deleteChatData);
    closeModal();
    setDeleteChatData(null);
    setShowMenu(false);
  };

  /* Add Member Event */

  const dialogAdd = (prod) => {
    settoggleAddMemberModel(!toggleAddMemberModel);
  };

  const onCompanyUserClick = (curentCompanyUser) => {
    const idExists = selectCompanyUser?.some(
      (item) => item?.id === curentCompanyUser?.id
    );
    if (!idExists) {
      setSelectCompanyUser([...selectCompanyUser, curentCompanyUser]);
    }
  };

  const onCompanyUserCancel = (curentCompanyUser) => {
    if (selectCompanyUser?.some((item) => item?.id !== user?.id)) {
      setSelectCompanyUser((prevData) =>
        prevData?.filter((item) => item?.id !== curentCompanyUser?.id)
      );
    }
  };

  const isCompanyUserFieldValid = () =>
    !!(
      formik.touched["campanyUser"] &&
      formik.errors["campanyUser"] &&
      selectCompanyUser?.length < 1
    );

  const getCompanyUserErrorMessage = () => {
    return (
      isCompanyUserFieldValid() && (
        <p className="error-msg">{formik.errors["campanyUser"]}</p>
      )
    );
  };

  /***********************/

  const AddMemberToConversation = async (selectCompanyUsersId) => {
    if (prod) {
      try {
        dispatch(
          addMemberConversationDataAsync({
            conversation_id: prod?.id,
            user_id: selectCompanyUsersId,
          })
        )
          .then((response) => {
            const res = response?.payload;
            if (res?.success === true) {
              if (selectCompanyUser?.length < prod?.users?.length) {
                toast.success("Members removed successfully", {
                  autoClose: 2000,
                  closeOnClick: false,
                });
              } else {
                toast.success(res?.message, {
                  autoClose: 2000,
                  closeOnClick: false,
                });
              }

              if (selectedInboxUser?.id === res?.data?.id) {
                dispatch(setInboxUser(res?.data));
              }
              getInboxAllUser();
            } else {
              toast.error(res?.message, {
                autoClose: 2000,
                closeOnClick: false,
              });
            }
            dialogAdd();
          })
          .catch((error) => {
            dialogAdd();
            console.log(error);
          });
      } catch (error) {
        dialogAdd();
        console.log(error);
      }
    }
  };

  const fetchEmployersInboxData = () => {
    const getData = setTimeout(() => {
      try {
        if (userName) {
          dispatch(
            fetchEmployersInboxDataAsync({
              search: userName,
              my_id: user?.id,
              company_id: user?.company_id,
            })
          )
            .then((response) => {
              const res = response?.payload;
              if (res?.result === true) {
                setAllCompanyUser(res?.company_users);

                if (res?.company_users?.length > 0) {
                  setCompanyUserHide(true);
                } else {
                  setCompanyUserHide(false);
                }
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      } catch (error) {
        console.log(error);
      }
    }, 1000);

    return () => clearTimeout(getData);
  };

  useEffect(() => {
    fetchEmployersInboxData();
  }, [userName]);

  useEffect(() => {
    if (prod?.users?.length > 0) {
      setSelectCompanyUser(prod?.users);
    }
  }, [prod]);

  return (
    <>
      <div
        className={`list-info icon-cursor ${
          selectedInboxUser?.id === prod?.id
            ? "selected"
            : prod?.reads?.length === 0
            ? "unread"
            : ""
        }`}
      >
        <div
          className="user-content"
          onClick={() => {
            populateChat();
            onMaskReadClickHandler(prod);
            onselectMessageUser(prod);
          }}
        >
          <div className="user-avatar">
            {prod?.users?.length > 0 && (
              <span>
                {prod?.users?.slice(0, 2)?.map((item, i) => (
                  <img
                    className="user-sub-avatar"
                    src={
                      item?.user_type === USER_TYPE.EMPLOYER
                        ? item?.companyy?.logo
                        : item?.avatar_link
                        ? item?.avatar_link
                        : "/image/client-img.png"
                    }
                  />
                ))}
              </span>
            )}
          </div>
          <div className="user-desc">
            {prod?.users?.length > 0 && (
              <span>
                {prod?.users?.slice(0, counter)?.map((item, i, arr) => (
                  <h4 className="m-0" key={i}>
                    {item?.first_name &&
                      `${item?.first_name} ${item?.last_name}`}
                    {i < arr.length - 1 && ", "}
                  </h4>
                ))}
                {prod?.users?.length > counter && (
                  <h4>{`, ${prod?.users?.length - counter} more ...`}</h4>
                )}
              </span>
            )}

            {userTypeEmp ? (
              <></>
            ) : (
              <>
                <p className="company-inbox">
                  {prod?.user?.companyy?.name && prod?.user?.companyy?.name}
                </p>
              </>
            )}
            {/* {!userTypeCan && (
              <p>{prod?.user?.roles && prod?.user?.roles[0]?.title}</p>
            )} */}
            <p>{prod?.job?.full_title && prod?.job?.full_title}</p>
            {prod?.latest_message !== null &&
            prod?.latest_message?.comments?.length >= 25 ? (
              <p>
                {prod?.latest_message?.comments &&
                  prod?.latest_message?.comments?.substr(0, 25) + "..."}
              </p>
            ) : (
              <p>
                {prod?.latest_message?.comments &&
                  prod?.latest_message?.comments}
              </p>
            )}
          </div>
        </div>
        <div className="user-option">
          <div className="pin-chat">
            <div className="dd-listitem-inbox">
              <>
                <button className="btn p-0" onClick={showSubMenu}>
                  <img
                    className="ep-inbox"
                    src="/image/ellipses.png"
                    alt="éllipses"
                  />
                </button>
                <div className={showMenu ? "cv-cions active" : "cv-cions"}>
                  <OutsideClickHandler
                    onOutsideClick={() => {
                      setShowMenu(false);
                    }}
                  >
                    <ul>
                      {userTypeEmp && (
                        <li onClick={(e) => dialogAdd(prod)}>
                          Add team member
                          <img src="/image/add-ep-inbox.png" />
                        </li>
                      )}
                      {user?.user_type === USER_TYPE.EMPLOYER && (
                        <li onClick={(e) => dialogDelete(prod)}>
                          Delete
                          <img src="/image/delete-ep-inbox.png" />
                        </li>
                      )}

                      {prod?.pins?.length === 0 ? (
                        <li onClick={() => onPinClickHandler(prod)}>
                          Pin
                          <img src="/image/pin-ep-inbox.png" />
                        </li>
                      ) : (
                        <li onClick={() => onPinClickHandler(prod)}>
                          Pinned
                          <img src="/image/pinned-ep-inbox.png" />
                        </li>
                      )}
                    </ul>
                  </OutsideClickHandler>
                </div>
              </>
            </div>
          </div>

          {prod?.pins?.length === 0 ? (
            <></>
          ) : (
            <div>
              <img
                className="dd-pinned"
                src={"/image/pinned.png"}
                class="cursor-pointer"
                onClick={() => onPinClickHandler(prod)}
              />
            </div>
          )}

          <p>{commentDate(prod?.updated_at)}</p>
        </div>
      </div>

      {toggleAddMemberModel && (
        <InvitePeopleModal
          toggleShareListModel={toggleAddMemberModel}
          closeModal={dialogAdd}
          formik={formik}
          allCompanyUser={allCompanyUser}
          allCompanyUserHide={allCompanyUserHide}
          onCompanyUserClick={onCompanyUserClick}
          selectCompanyUser={selectCompanyUser}
          getCompanyUserErrorMessage={getCompanyUserErrorMessage}
          onCompanyUserCancel={onCompanyUserCancel}
          userName={userName}
          setUserName={setUserName}
        />
      )}

      {deleteChat && (
        <ActionModal
          closeModal={closeModal}
          modalArt="/image/warning-wishlist.png"
          heading="Double-Check"
          content="Are you sure you want to delete this conversation?"
        >
          <button className="btn btn-black" onClick={() => closeModal()}>
            No, keep it.
          </button>
          <button
            className="btn btn-outline-black"
            onClick={() => deleteComment()}
          >
            Yes, delete it.
          </button>
        </ActionModal>
      )}
    </>
  );
};

export default ConversationCard;
