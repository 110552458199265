import { useNavigate, Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux"
import { getAvailableJobPostsDataAsync, setBuyJobsModelModel } from "../../../redux/slices/JobDecisionSlice";


const BaseInfo = ({
  totalApplicants,
  jobListingsCount,
  draftJobsCount,
  favoriteCount
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  
  const { user, inboxCount } = useSelector((state) => state.auth);
  const { buyJobsModel } = useSelector((state) => state.jobDecision);

  const [availableJobs, setAvailableJobs] = useState("")
  const [productPrice, setProductPrice] = useState("")
  const [jobPurchased, setJobPurchased] = useState(false);
  const [buyJobsQuantity, setBuyJobsQuantity] = useState(1);
  const [jobPurchasedMore, setJobPurchasedMore] = useState("");

  const onFavouritListClick = () => {
    if (favoriteCount > 0) navigate("/employer-Favourit-list");
  };

  const handleJobModel = () => {
    dispatch(setBuyJobsModelModel(!buyJobsModel));
  }


  const getAvailableJobPost = async () => {
    dispatch(getAvailableJobPostsDataAsync())
    .then((response) => {
      const res = response?.payload;
      if (res?.result === true) {
        setAvailableJobs(res)
      }
    })
    .catch((error) => {
      console.log(error);
    });
  }

  const onPostJob = () => {
    navigate("/post-job");
  }

  const handleInbox = () => {
    navigate("/employer-inbox");
  }


  useEffect(() => {
    getAvailableJobPost();
  }, [])

  return (
    <section class="candidate-dashbord-sec">
      <div class="container container-site">
        <div class="space-inner-row">
          <div class="employee-job-dasboard">
            <a href="#new_applicants" class="anchor-cards-job-1 card-1">
              <div class="job-applied-col job-employee-cards">
                <img src="/image/new-applicant.png" alt="" />
                <div class="card-employee-info">
                  <p class="job-title-candidate-dashboard">New applicants</p>
                  <p class="job-applied-candidate-dashboard">
                    {" "}
                    {totalApplicants}
                  </p>
                </div>
              </div>
            </a>
            <a href="#job_listing" class="anchor-cards-job-2 card-2">
              <div class="job-applied-col job-employee-cards">
                <img src="/image/user-list.png" alt="" />
                <div class="card-employee-info">
                  <p class="job-title-candidate-dashboard">Job listing</p>
                  <p class="job-applied-candidate-dashboard">
                    {" "}
                    {jobListingsCount}
                  </p>
                </div>
              </div>
            </a>
            <a href="#draft_jobs" class="anchor-cards-job-3 card-3">
              <div class="job-applied-col job-employee-cards">
                <img src="/image/draft-job.png" alt="" />
                <div class="card-employee-info">
                  <p class="job-title-candidate-dashboard">Draft jobs</p>
                  <p class="job-applied-candidate-dashboard"> {draftJobsCount}</p>
                </div>
              </div>
            </a>
            <a
              class="anchor-cards-job-4 card-4 icon-cursor"
              onClick={() => onFavouritListClick()}
            >
              <div class="job-applied-col job-employee-cards">
                <img src="/image/user-register.png" alt="" />
                <div class="card-employee-info">
                  <p class="job-title-candidate-dashboard">
                    {" "}
                    Favourite candidates{" "}
                  </p>
                  <p class="job-applied-candidate-dashboard">{favoriteCount}</p>
                </div>
              </div>
            </a>
            <a
              class="anchor-cards-job-5 card-5 icon-cursor"
              onClick={() => onPostJob()}
            >
              <div class="job-applied-col job-employee-cards">
                <img src="/image/post-job.png" alt="" />
                <div class="card-employee-info">
                  <p class="job-title-candidate-dashboard">
                    {" "}
                    Post a job{" "}
                  </p>
                  <p class="job-desc-candidate-dashboard">
                    You have <strong>{availableJobs?.availableJobPosts || 0}</strong> job posts left
                  </p>
                </div>
              </div>
            </a>
            <a
              class="anchor-cards-job-6 card-6 icon-cursor"
              onClick={() => handleInbox()}
            >
              <div class="job-applied-col job-employee-cards">
                <img src="/image/index-emp.png" alt="" />
                <div class="card-employee-info">
                  <p class="job-title-candidate-dashboard">
                    {" "}
                    Inbox{" "}
                  </p>
                  <p class="job-applied-candidate-dashboard">{inboxCount && inboxCount}</p>
                </div>
              </div>
            </a>
            <button className="btn btn-black btn-lg" onClick={handleJobModel}>Buy more job posts +</button>

          </div>
          <div className="text-right mb-4 mr-4">
          </div>
        </div>
      </div>
    </section>
  );
};

export default BaseInfo;
