import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { setSelectJobId } from "../../../redux/slices/JobDetailSlice";
import {
  setWithdrawJobId,
  withdrawJobAsync,
} from "../../../redux/slices/candidateDashboardSlice";
import {
  markAsReadInboxDataAsync,
  setInboxUser,
} from "../../../redux/slices/InboxSlice";
import getCurrentFormattedDate from "../../../utils/getCurrentFormattedDate";
import "./JobRecord.css";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { getColor } from "../../../utils/progressbarFn";
import { setInboxCount } from "../../../redux/slices/AuthSlice";
import { useState } from "react";

const JobAppliedRight = ({
  JobData,
  selectedJobId,
  hashId,
  slug,
  date,
  conversation,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, inboxCount } = useSelector((state) => state.auth);
  const [statusToggle, setStatusToggle] = useState(false)

  /* React Circular Progress Bar*/
  const OverAllMatch =
    JobData?.overall_match !== undefined && JobData?.overall_match !== null
      ? JobData?.overall_match
      : 0;

  const progressValue = parseInt(OverAllMatch, 10);

  const onDetailJobHandler = () => {
    if (slug && hashId) {
      navigate(`/jobs/${slug}/?currentJobId=${hashId}`);
    }
  };

  const onWithdrawJobHandler = () => {
    if (user !== null) {
      dispatch(withdrawJobAsync({ userId: user?.id, jobId: selectedJobId }))
        .then((response) => {
          const res = response?.payload;
          if (res?.result === true) {
            dispatch(setWithdrawJobId(selectedJobId));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleStatusChange = ()=>{
    // Your code here to change job status
    setStatusToggle(!statusToggle);
  };

 
  const handleInbox = (conversation, conversationData) => {
    if (conversationData) {
      if (conversation) {
        dispatch(
          markAsReadInboxDataAsync({ conversation_id: conversationData?.id })
        )
          .then((response) => {
            const res = response?.payload;
            if (res?.success === true) {
              const currentInboxCount = inboxCount;
              if (currentInboxCount > 0) {
                dispatch(setInboxCount(currentInboxCount - 1));
              }             
            }
            dispatch(setInboxUser(conversationData));
            navigate("/candidate-inbox");
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        dispatch(setInboxUser(conversationData));
        navigate("/candidate-inbox");
      }
    }
  };

  return (
    <div className="job-announcements">
      <div className="job-announcements-col-candidate">
        <div className="d-md-flex d-none align-items-center justify-content-end gap-2">
          <div
            style={{
              width: 45,
              height: 45,
              position: "relative",
              display: "inline-block",
            }}
          >
            <CircularProgressbar
              minValue="0"
              strokeWidth={12}
              value={progressValue}
              text={`${progressValue}%`}
              counterClockwise={false}
              styles={buildStyles({
                strokeLinecap: "round",
                pathTransitionDuration: 0.5,
                pathColor: getColor(progressValue),
                textColor: getColor(progressValue),
                textSize: "24px",
                trailColor: "#d6d6d6",
              })}
            />
          </div> 
          <div
            className={`chat-on cursor-pointer ${
              JobData?.hiringStatus?.length -1 &&  "d-none"
            }`}
            onClick={handleStatusChange}
          >
            {
              statusToggle && (<div className="status-box">
                  <div className="hiring-status-box">
                    <h3>Hiring status</h3>
                    <div className="status-content">
                    <img src="./image/icon-nodes.png" alt="chat" />
                      <p>{JobData?.hiringStatus?.name}</p>
                    </div>
                  </div>
              </div>)
            }
            <img src="./image/icon-status.png" alt="chat" />
            {conversation !== null && conversation > 0 && (
              <span>{conversation}</span>
            )}
          </div>
          <div
            className={`chat-on cursor-pointer ${
              conversation === null && "chat-op"
            }`}
            onClick={() => handleInbox(conversation, JobData?.conversationData)}
          >
            <img src="./image/chat-icon.png" alt="chat" />
            {conversation !== null && conversation > 0 && (
              <span>{conversation}</span>
            )}
          </div>
        </div>
        <div className="job-time-wishlist job-app p-0">
          <a
            className="btn btn-outline-black w-100"
            onClick={() => onDetailJobHandler()}
          >
            <img src="/image/view-candidate.png" alt="apply" className="mr-1" />
            View detail
          </a>

          <a
            className="btn btn-black"
            onClick={() => {
              navigate(`/job-quesation/${selectedJobId}`);
            }}
          >
            <img src="/image/canidate-q.png" alt="apply" className="mr-1" />
            Sample questions
          </a>
          <div className="job-date-candidate">
            <p>Date Applied: {getCurrentFormattedDate(date)}</p>
          </div>         
        </div>
      </div>
    </div>
  );
};

export default JobAppliedRight;
