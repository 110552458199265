import { useNavigate, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import getDays from "../../../utils/getDays";
import {
  setActiveTab,
  setPostJobId,
  setPostJobMode,
} from "../../../redux/slices/AuthSlice";
import { setPostJobEditForm } from "../../../redux/slices/employerDashboardSlice";
import "./JobRecord.css";

const JobDraftRight = ({
  selectedJobId,
  date,
  onChangeJobStatus,
  openDeleteItemHandler,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const postJobClickHandler = () => {
    onChangeJobStatus(selectedJobId);
  };

  const editJobClickHanlder = (id) => {
    dispatch(setActiveTab("employer"));
    dispatch(setPostJobMode("edit"));
    dispatch(setPostJobEditForm("draft"));
    dispatch(setPostJobId(id));
    navigate("/post-job");
  };

  return (
    <div className="job-announcements">
      <div className="job-announcements-col btn-size h-100">
        <div class="edit-icon-emply edit-icon-emply-draft edit-post-container">
          <img
            src="/image/edit-dash.png"
            alt=""
            onClick={() => editJobClickHanlder(selectedJobId)}
          />
          <img
            src="/image/delete-dash.png"
            alt=""
            onClick={(e) => {
              e.stopPropagation();
              openDeleteItemHandler(selectedJobId, true);
            }}
          />
        </div>
        <div className="post-job-table mb-md-0 mb-2 pb-md-4">
          <Link href="#" onClick={() => postJobClickHandler()}>
            <button>Post this job</button>
          </Link>
        </div>
        <div className="edit-post-container mb-md-0 mb-2 pb-md-4">      
            <button onClick={() => editJobClickHanlder(selectedJobId)}>
              Edit{" "}
              <img
                src="/image/edit-dash-m.png"
                alt=""
                className="edit-post-container-icon"
              />
            </button>
        </div>
        <div className="delete-post-container mb-md-0 mb-2 pb-md-4">        
            <button onClick={(e) => {
              e.stopPropagation();
              openDeleteItemHandler(selectedJobId, true);
            }}>
              Remove{" "}
              <img
                src="/image/delete-dash-m.png"
                alt=""
                className="delete-post-container-icon"
              />
            </button>       
        </div>
        <div class="job-time-wishlist mt-md-5">
          <div className="job-eployee-table-col">
            <p className="day">
              {date
                ? getDays(date) > 1
                  ? getDays(date) + " days ago"
                  : getDays(date) > 0
                  ? getDays(date) + " day ago"
                  : "Today"
                : ""}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobDraftRight;
