import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import OutsideClickHandler from "react-outside-click-handler";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import StarRatings from "react-star-ratings";
import {
  setFeedbackModel,
  userFeedBackAsync,
} from "../../../redux/slices/homeSlice";
import "./WebsiteFeedbackModel.css";


const WebsiteFeedbackModel = ({ modelHandler }) => {
  const dispatch = useDispatch();
  const { openFeedbackModel } = useSelector((state) => state.home);

  const [loader, setLoader] = useState(false);

  const formik = useFormik({
    initialValues: {
      message: "",
      starRating: 0, // field for storing rating
    },
    validationSchema: Yup.object({
      message: Yup.string().required("Please enter feedback"),
    }),
    onSubmit: async (data, { resetForm }) => {
      const { message, starRating } = data;
      try {
        setLoader(true);
        dispatch(
          userFeedBackAsync({
            message: message,
            star_rating: starRating, // use the updated rating
          })
        )
          .then((response) => {
            const res = response?.payload;

            if (res?.success === true) {
              toast.success(res?.message, {
                autoClose: 2000,
                closeOnClick: false,
              });          
            } else {
              toast.error(res?.errors, {
                autoClose: 2000,
                closeOnClick: false,
              });
            }
            window.scrollTo({ top: 0, behavior: "smooth" });
            formik.reset();
            dispatch(setFeedbackModel(false));
            setLoader(false);
          })
          .catch((error) => {
            dispatch(setFeedbackModel(false));
            setLoader(false);
            console.log(error);
          });
      } catch (error) {
        dispatch(setFeedbackModel(false));
        setLoader(false);
        console.log(error);
      }
    },
  });

  // Updated changeRating function
  const changeRating = (newRating) => {
    formik.setFieldValue("starRating", newRating); // Set the correct field
  };

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <p className="error-msg">{formik.errors[name]}</p>
      )
    );
  };

  return (
    <section
      className={`model-popup model-popup-2 ${
        openFeedbackModel === false && "hide-model-popup"
      }`}
    >
      <div className="feedback-model-popup-bg">
        <OutsideClickHandler
          onOutsideClick={() => {
            dispatch(setFeedbackModel(false));
          }}
        >
          <div className="feed-back-content">
            <div
              className="close-resmodle-file"
              onClick={() => dispatch(setFeedbackModel(false))}
            >
              <img src="/image/cut-black.png" alt=" " />
            </div>
            <div className="feed-back-heading">Feedback</div>
            <p className="feed-back-text">
              We’ve just launched, and your feedback is incredibly valuable to
              us. As we work to continuously improve, your insights will help
              shape the future of this product. Thank you for being part of our
              journey and we appreciate your patience as we make it even better!
            </p>
            <form onSubmit={formik.handleSubmit}>
              <div className="rating-row">
                <p>How would you rate your experience using our job board?</p>
                <div className="rateing-star">
                  <StarRatings
                    rating={formik.values.starRating}
                    starRatedColor="black"
                    starHoverColor="black"
                    starDimension="25px"
                    changeRating={changeRating}
                    numberOfStars={5}
                    name="starRating"
                    starEmptyStyle={{
                      stroke: "black",
                      strokeWidth: 1,
                    }}
                  />
                </div>
              </div>
              <div
                className={`text-are-feed-back ${
                  isFormFieldValid("message") && "error-input"
                }`}
              >
                <textarea
                  required=""
                  rows="5"
                  placeholder="We’d love to hear your thoughts! Let us know what worked well and how we can improve your job search or hiring experience."
                  className="feed-back-txt-are"
                  spellCheck="false"
                  name="message"
                  value={formik.values.message}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {getFormErrorMessage("message")}
                <p className="feedback-tabs-msg">0/300</p>
              </div>
              <button type="submit" className="btn-feed-back">
                {loader ? (
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  `Submit`
                )}
              </button>
            </form>
          </div>
        </OutsideClickHandler>
      </div>
    </section>
  );
};

export default WebsiteFeedbackModel;
