import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import ShowRows from "../../../components/Common/Admin/ShowRows/ShowRows";
import TablePagination from "../../../components/Common/Admin/TablePagination/TablePagination";
import { BUY_BULK_SORT, SORT_DIRECTION } from "../../../constants";
import {
  buyBulkCreditsDataAsync,
  getBulkCreditsDataAsync,
  getBulkCreditsPurchasedDataAsync,
  setTotalBulkResumeCredit,
} from "../../../redux/slices/buyBulkAnalysisSlice";
import capitalizeLetter from "../../../utils/capitalizeLetter";
import getCurrentFormattedDate from "../../../utils/getCurrentFormattedDate";
import "./index.css";

const BuyBulkAnalysis = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [sortBy, setSortBy] = useState("");
  const [sortDirection, setSortDirection] = useState("");

  const [availableBulkCredits, setAvailableBulkCredits] = useState(0);
  const [bulkCreditsData, setBulkCreditsData] = useState([]);
  const [creditsPurchaseData, setCreditsPurchaseData] = useState([]);

  const onSortByHandler = (value) => {
    if (sortBy === value) {
      setSortDirection((prevDirection) =>
        prevDirection === SORT_DIRECTION.ASC
          ? SORT_DIRECTION.DESC
          : SORT_DIRECTION.ASC
      );
    } else {
      setSortDirection(SORT_DIRECTION.DESC);
    }
    setSortBy(value);
  };

  const onPageChange = (page) => {
    if (page >= 1 && page <= totalPages) setCurrentPage(page);
  };

  const onChangePerPageHandler = (value) => {
    setPerPage(value);
  };

  const onBuyCreditsClick = async (id) => {
    try {
      if (id) {
        dispatch(
          buyBulkCreditsDataAsync({
            bundle_id: id,
            user_email: user?.email,
            user_id: user?.id,
          })
        )
          .then(async (response) => {
            const res = response?.payload;
            if (res?.result === true) {
              const url = res?.checkout_url;
              const newWindow = window.open(
                url,
                "_self",
                "noopener,noreferrer"
              );
              if (newWindow) {
                newWindow.opener = null; // Prevent new tab from having access to current tab
                newWindow.location.href = url; // Navigate the new tab to the specified URL
              } else {
                // Fallback if opening in new tab fails
                navigate(url); // Navigate within the current tab if new tab failed to open
              }
            } else {
              setBulkCreditsData([]);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getBulkCreditsData = async () => {
    try {
      dispatch(getBulkCreditsDataAsync())
        .then(async (response) => {
          const res = response?.payload;
          if (res?.result === true) {
            const AvailableCredits = res?.available_bulk_credits
              ? res?.available_bulk_credits
              : 0;
            setAvailableBulkCredits(AvailableCredits);
            setBulkCreditsData(res?.data);
            dispatch(setTotalBulkResumeCredit(AvailableCredits));
          } else {
            setBulkCreditsData([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const getBulkCreditsPurchasedData = async () => {
    try {
      dispatch(
        getBulkCreditsPurchasedDataAsync({
          userId: user?.id,
          currentPage: currentPage,
          perPage: perPage,
          sortBy: sortBy,
          sortDirection: sortDirection,
        })
      )
        .then(async (response) => {
          const res = response?.payload;
          if (res?.result === true) {
            setCreditsPurchaseData(res?.data?.data);
            setTotalPages(res?.last_page);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getBulkCreditsPurchasedData();
  }, [currentPage, perPage, sortDirection]);

  useEffect(() => {
    getBulkCreditsData();
  }, []);

  return (
    <>
      <div className="page-title-heading page-space">
        <h2>Buy Bulk analysis</h2>
      </div>
      <div className="page-title-sub-heading">
        <p>
          PolyCareers credits offers powerful resume analysis features that
          streamlines your hiring process. With advanced algorithms, it provides
          insightful assessments of candidate resumes, helping you make informed
          decisions quickly and efficiently.
        </p>
      </div>
      <div className="coin-container">
        <div className="coin-dd">
          <img className="pc-img" alt="img" src="./image/coin-pc.png" />
          <div className="flex-auto">
            <h3>
              You have {availableBulkCredits ? availableBulkCredits : 0}{" "}
              PolyCareers credits available
            </h3>
            <div className="coin-panel">
              {bulkCreditsData?.length > 0 &&
                bulkCreditsData?.map((prod, index) => (
                  <div className="single-panel">
                    <p className="m-0">
                      {prod?.credits} Credits for ${prod?.unit_amount} (approx.
                      ${prod?.price_per_resume} per resume)
                    </p>
                    <button
                      className="btn btn-black"
                      onClick={() => onBuyCreditsClick(prod?.id)}
                    >
                      Buy
                    </button>
                  </div>
                ))}
            </div>
          </div>
        </div>
        <div className="coin-dd-buttom">
          <div className="coin-dd-buttom-p">
            <p>
              Processing time varies by file size—typically about one minute per
              resume. We'll email you when it's ready, perfect for a quick
              break! Enjoy fast, accurate results at an affordable price.
            </p>
          </div>
          <div>
            <img src="/image/bulk-resume-banner.png" />
          </div>
        </div>
      </div>

      {creditsPurchaseData?.length > 0 && (
        <div className="page-space bg-clr-admin">
          <div className="page-title-heading">
            <h2>PolyCareers credit purchases</h2>
          </div>{" "}
          <ShowRows
            perPage={perPage}
            onChangePerPageHandler={onChangePerPageHandler}
          />
          <div className="department-table-imge">
            <div className="col-user-table">
              <div className="table-responsive">
                <table className="depart-table-left">
                  <thead className="tb-heading-user">
                    <tr>
                      <th scope="col">
                        <div className="th-admin-heading">
                          Credits purchased
                        </div>
                      </th>
                      <th scope="col">
                        <div className="th-admin-heading">Price</div>
                      </th>
                      <th scope="col">
                        <div className="th-admin-heading">
                          Purchase date{" "}
                          <img
                            className="arrow-icon icon-cursor"
                            src={
                              sortBy === BUY_BULK_SORT.CREATED_AT &&
                              sortDirection === SORT_DIRECTION.DESC
                                ? "/image/bars-arrow-down.png"
                                : "/image/bars-arrow-up.png"
                            }
                            alt=""
                            onClick={() =>
                              onSortByHandler(BUY_BULK_SORT.CREATED_AT)
                            }
                          />{" "}
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="tb-user">
                    {creditsPurchaseData?.length > 0 &&
                      creditsPurchaseData?.map((item, index) => (
                        <tr>
                          <td>
                            {item?.product_name ? item?.product_name : "N/A"}
                          </td>
                          <td>
                            {item?.amount
                              ? `$${item?.amount} ${capitalizeLetter(
                                  item?.currency
                                )}`
                              : "N/A"}
                          </td>
                          <td>
                            {item?.updated_at
                              ? getCurrentFormattedDate(item?.updated_at)
                              : "N/A"}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              {creditsPurchaseData?.length > 0 && (
                <TablePagination
                  totalPages={totalPages}
                  currentPage={currentPage}
                  onPageChange={onPageChange}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default BuyBulkAnalysis;
