import React, { Fragment, useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "react-bootstrap";
import OutsideClickHandler from "react-outside-click-handler";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import MessageCard from "../MessageCard/MessageCard";
import {
  deleteInboxChatDataAsync,
  editInboxChatDataAsync,
  getInboxUserMsgAsync,
  saveInboxUserMsgAsync,
} from "../../../../redux/slices/InboxSlice";
import useAblyHook from "../../../../utils/useAblyHook";
import { CANDIDATE, EMPLOYER, USER_TYPE } from "../../../../constants";
import setDateTime from "../../../../utils/setDateTime";

const InboxRightSide = ({
  fileUpload,
  mobileChat,
  populateChat,
  modalRef,
  messageData,
  scrollToBottom,
  fileUploadHandler,
  resetFile,
}) => {
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);
  const selectedInboxUser = useSelector(
    (state) => state.Inbox?.selectedInboxUser || {}
  );
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [toggleEmojiPicker, setToggleEmojiPicker] = useState(false);
  const [toggleMemberStickyNote, setToggleMemberStickyNote] = useState(false);
  const [commentMsg, setCommentMsg] = useState([]);
  const [commentText, setCommentText] = useState("");
  const [counter, setCounter] = useState(2);

  const userTypeEmp = EMPLOYER.includes(user?.user_type);
  const userTypeCan = CANDIDATE.includes(user?.user_type);

  // Use the custom hook for Ably
  const { channel, error } = useAblyHook(
    `private-conversation.${selectedInboxUser?.id}`
  );

  const toggleEmojiPickerHandler = () => {
    setToggleEmojiPicker((current) => !current);
    scrollToBottom();
  };

  const addEmojiToInput = (e) => {
    setCommentText((current) => current + e.native);
  };

  const handleCommentBoxChange = (e) => {
    const value = e.target.value;

    setCommentText(value);
    if (value === null || value === undefined || value === "") {
      setDisableSubmit(true);
      return;
    }
    setDisableSubmit(false);
  };

  const clearCommentBox = () => setCommentText("");

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  const onDeleteInboxHandler = (prod) => {
    if (user?.id && selectedInboxUser && prod) {
      dispatch(
        deleteInboxChatDataAsync({
          message_id: prod?.id,
        })
      )
        .then((response) => {
          const res = response?.payload;
          if (res?.success === true) {
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const onEditInboxHandler = (prod, message) => {
    if (user?.id && selectedInboxUser && prod) {
      dispatch(
        editInboxChatDataAsync({
          message_id: prod?.id,
          message: message,
        })
      )
        .then((response) => {
          const res = response?.payload;
          if (res?.success === true) {
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleSubmit = async () => {
    if (user?.id && selectedInboxUser) {
      dispatch(
        saveInboxUserMsgAsync({
          conversation_id: selectedInboxUser?.id,
          message: commentText,
          fileUpload: fileUpload,
        })
      )
        .then((response) => {
          const res = response?.payload;
          if (res?.success === true) {
            setCommentText("");
            resetFile();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const getInboxMsg = async () => {
    if (user?.id && selectedInboxUser && selectedInboxUser?.id) {
      setCommentMsg([]);
      dispatch(
        getInboxUserMsgAsync({
          conversation_id: selectedInboxUser?.id,
        })
      )
        .then((response) => {
          const res = response?.payload;
          if (res?.success === true) {
            if (res?.data?.length > 0) {
              setCommentMsg(res?.data);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  useEffect(() => {
    getInboxMsg();
    setToggleMemberStickyNote(false);
  }, [selectedInboxUser]);

  useEffect(() => {
    if (commentMsg?.length !== 0) {
      scrollToBottom();
    }
  }, [commentMsg]);

  useEffect(() => {}, [commentMsg]);

  useEffect(() => {
    if (fileUpload !== null) {
      handleSubmit();
    }
  }, [fileUpload]);

  useEffect(() => {
    if (channel) {
      setCommentMsg([]);
      // console.log("channel", channel);
      const handleChannelMessage = (message) => {
        if (message?.data?.data) {
          setCommentMsg(message?.data?.data);
        }
      };
      channel.subscribe("message", handleChannelMessage);
      return () => {
        channel.unsubscribe("message", handleChannelMessage);
      };
    }
  }, [channel]);

  if (Object?.keys(selectedInboxUser)?.length === 0) return <></>;

  return (
    <>
      <div className={`msg-wrap ${mobileChat ? "d-block" : ""} `}>
        <div className="inbox-wrapper">
          <div
            className="inbox-header"
            onClick={() => setToggleMemberStickyNote(!toggleMemberStickyNote)}
          >
            <div className="clinet-profile size-w-d">
              <div className="d-md-none d-flex">
                <Button variant="link" className="p-0" onClick={populateChat}>
                  <img src="/image/form-back-arrow.png" alt="" />
                </Button>
              </div>

              {selectedInboxUser?.users?.length > 0 && (
                <span className="d-flex">
                  {selectedInboxUser?.users?.slice(0, 2)?.map((item, i) => (
                    <img
                      className="user-sub-avatar clint-avatar-right"
                      src={
                        item?.user_type === USER_TYPE.EMPLOYER
                          ? item?.companyy?.logo
                          : item?.avatar_link
                          ? item?.avatar_link
                          : "/image/client-img.png"
                      }
                    />
                  ))}
                </span>
              )}

              <div className="clint-info clint-info-right">
                {selectedInboxUser?.users?.length > 0 && (
                  <p className="user-name-inbox">
                    {selectedInboxUser?.users
                      ?.slice(0, counter)
                      ?.map((item, i, arr) => (
                        <React.Fragment key={i}>
                          {item?.first_name &&
                            `${item?.first_name} ${item?.last_name}`}
                          {i < arr.length - 1 && ", "}
                        </React.Fragment>
                      ))}
                    {selectedInboxUser?.users?.length > counter &&
                      `, ${
                        selectedInboxUser?.users?.length - counter
                      } more ...`}
                  </p>
                )}

                {EMPLOYER.includes(user?.user_type) ? (
                  <></>
                ) : (
                  <>
                    <p className="clinet-co">
                      {" "}
                      {selectedInboxUser?.user?.companyy?.name &&
                        selectedInboxUser?.user?.companyy?.name}
                    </p>
                  </>
                )}
                {!userTypeCan && (
                  <p className="clinet-rank">
                    {" "}
                    {selectedInboxUser?.user?.roles &&
                      selectedInboxUser?.user?.roles[0]?.title}
                  </p>
                )}

                <p className="clinet-rank">
                  {" "}
                  {selectedInboxUser?.job?.full_title &&
                    selectedInboxUser?.job?.full_title}
                </p>
              </div>
            </div>

            {toggleMemberStickyNote && selectedInboxUser?.users?.length > 0 && (
              <div class="chat-box-note">
                <div class="chat-header-note">
                  <strong>Team Chat</strong>
                </div>
                <ul class="chat-list-note">
                  {selectedInboxUser?.users?.map((item, i, arr) => (
                    <React.Fragment key={i}>
                      <li>
                        <span class="user-status-list"></span>
                        <img
                          src={
                            item?.user_type === USER_TYPE.EMPLOYER
                              ? item?.companyy?.logo
                              : item?.avatar_link
                              ? item?.avatar_link
                              : "/image/client-img.png"
                          }
                          alt="Mathews Jordan"
                        />
                        <p class="name-chat-own">
                          {" "}
                          {item?.first_name &&
                            `${item?.first_name} ${item?.last_name}`}
                        </p>
                      </li>
                    </React.Fragment>
                  ))}
                </ul>
              </div>
            )}
          </div>
          <div className="inbox-body" ref={modalRef}>
            <div style={{ padding: "20px" }}>
              {commentMsg?.length > 0 &&
                commentMsg?.map((item, index) => (
                  <Fragment key={index}>
                    <div className="comment-model-title">
                      <hr />
                      <div className="chat-date-b">
                        <h1>{setDateTime(item?.day)}</h1>
                      </div>
                    </div>

                    {item?.comments?.length > 0 && (
                      <div className="chat-slot">
                        {item?.comments?.map((itm, ind) => (
                          <MessageCard
                            key={ind}
                            item={itm}
                            myMsg={itm?.user?.id === user?.id}
                            onDeleteInboxHandler={onDeleteInboxHandler}
                            onEditInboxHandler={onEditInboxHandler}
                          />
                        ))}
                      </div>
                    )}
                  </Fragment>
                ))}
            </div>
          </div>
          <div className="inbox-footer">
            <div className="emoji-container">
              {toggleEmojiPicker === true ? (
                <OutsideClickHandler
                  onOutsideClick={() => {
                    setToggleEmojiPicker(false);
                  }}
                >
                  <Picker
                    data={data}
                    theme="light"
                    onEmojiS
                    onEmojiSelect={(e) => addEmojiToInput(e)}
                  />
                </OutsideClickHandler>
              ) : null}
            </div>
            <div className="chat-comment-f">
              <div className="chat-form">
                <input
                  type="text"
                  name=""
                  id="comment-f"
                  placeholder="Type here..."
                  value={commentText}
                  onChange={handleCommentBoxChange}
                  onKeyDown={handleKeyPress}
                />
                <img
                  src="/image/attchment.png "
                  alt=" "
                  className="opacity-50"
                  onClick={fileUploadHandler}
                />
                <img
                  src="/image/ssmile.png"
                  alt=""
                  onClick={() => toggleEmojiPickerHandler()}
                />
              </div>
              <div className="send-comment icon-cursor">
                <button
                  type="submit"
                  onClick={handleSubmit}
                  disabled={!commentText.length}
                >
                  <img src="/image/send-chat.png" alt="" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InboxRightSide;
