import React, { useEffect, useState } from 'react'
import './index.css'
import TablePagination from '../../../components/Common/Admin/TablePagination/TablePagination'
import CandidateCard from '../../../components/Common/CandidateCard/CandidateCard'
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import API from "../../../api";
import { Zoom } from 'react-reveal';

const JobPosted = () => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const [jobs, setJobs] = useState([])
  const [jobTitle, setJobTitle] = useState([]);
  const [jobTitleSaved, setJobTitleSaved] = useState('');
  const [byDate, setByDate] = useState([]);
  const [byDateSaved, setByDateSaved] = useState('');
  const [jobStatusSaved, setJobStatusSaved] = useState('');


  useEffect(()=>{
    getAllJobs();
  },[jobTitleSaved, byDateSaved, jobStatusSaved])


  const getAllJobs = async () => {
    try {
      let dateVal = ''
      if(byDateSaved.value) {
        dateVal= byDateSaved.value
      } else {
        dateVal = ''
      }
      await API.get(`/job-board/get-company-posted-jobs?user_id=${user?.id}&is_active=${jobStatusSaved}&job_title=${jobTitleSaved}&date_posted=${dateVal}`).then((res) => {
        if (res?.data.result) {
          setJobs(res.data.data)
          // setJobTitle
          setJobTitle(res.data.filters.job_titles)
          setByDate(res.data.filters.date_posted)
        }
      })
    } catch (error) {
      if (error) {
        console.log(error);
      }
    }
  }

  return (
    <div className="page-space bg-clr-admin">
      <div className="page-title-heading">
        <h2>My job post & more</h2>
      </div>{" "}
      <div className="filter-search-bar">
        <div className="two-drop-role">
          <div className="col-left-adminfilter">
            <p className="filter-label-admin">Job title</p>
            <div className="admin-status-filter">
              <div
                id="dropdown-boxId2"
                className="company-form-dropdownBox1"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <div className="text-filter-admin">
                  <p className={` ${"text-filter-select"}`}>
                    {jobTitleSaved ? jobTitleSaved : 'Job title'}
                  </p>

                  {jobTitleSaved ? <img src="/image/cross-port.png" alt="" onClick={() => setJobTitleSaved('')} /> : <img src="/image/admin-d.png" alt="" />}
                </div>
              </div>
              {jobTitle?.length > 0 && (
                <div className="promotion-status-dropdown">
                  <ul className="list-job-title-company">
                    {jobTitle?.map((item, index) => (
                      <li
                        key={index}
                        onClick={() => {setJobTitleSaved(item)}}
                      >
                        {item}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>

          <div className="col-left-adminfilter">
            <p className="filter-label-admin">Status</p>
            <div className="admin-status-filter">
              <div
                id="dropdown-boxId2"
                className="company-form-dropdownBox1"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <div className="text-filter-admin"> 
                <p className={` ${"text-filter-select"}`}>
                    {jobStatusSaved === '1' ? 'Active' : jobStatusSaved === '0' ? 'In-Active' : 'Status'}
                  </p>

                  {jobStatusSaved ? <img src="/image/cross-port.png" alt="" onClick={() => setJobStatusSaved('')} /> : <img src="/image/admin-d.png" alt="" />}
                </div>
              </div>
              <div className="promotion-status-dropdown">
                <ul className="list-job-title-company">
                  <li onClick={() => {setJobStatusSaved('1');}}>Active</li>
                  <li onClick={() => {setJobStatusSaved('0');}}>Inactive</li>
                </ul>
              </div>
            </div>
          </div>

          <div className="col-left-adminfilter">
            <p className="filter-label-admin">By date</p>
            <div className="admin-status-filter">
              <div
                id="dropdown-boxId2"
                className="company-form-dropdownBox1"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <div className="text-filter-admin">
                  <p className={` ${"text-filter-select"}`}>
                    {byDateSaved ? byDateSaved.label : 'Date posted'}
                  </p>

                  {byDateSaved ? <img src="/image/cross-port.png" alt="" onClick={() => setByDateSaved('')} /> : <img src="/image/admin-d.png" alt="" />}
                </div>
              </div>
              {byDate?.length > 0 && (
                <div className="promotion-status-dropdown">
                  <ul className="list-job-title-company">
                    {byDate?.map((item, index) => (
                      <li
                        key={index}
                        onClick={() => {
                          setByDateSaved(item);
                        }}
                      >
                        {item?.label}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="cand-card-list">
        <Zoom>
        <div className="card-grid">
          {jobs?.map((card) => (
            <CandidateCard props={card} getAllJobs={getAllJobs}/>
          ))}

        </div>
        </Zoom>
        {jobs.length === 0 && (
            <div className='no-interview text-center'><p>Currently there are no Jobs</p></div>
          )}
      </div>
    </div>
  )
}

export default JobPosted