import React from 'react'
import './index.css'

const BuyMorePost = () => {
  return (
    <>
    <div className="page-title-heading page-space">
      <h2>Buy more posts</h2>
    </div>
    <div className="coin-container page-space">
      <div className="coin-dd">
        <img className="pc-img" alt="img" src="./image/buy-more-icon.png" />
        <div className="flex-auto">
          <h3>You have 0 Jobs posts</h3>
          <p>
          PolyCareers offers 2 free job posts, always. If you need more, you can buy additional posts here for only $59. For greater visibility and faster results, buy a job post first, then promote it for an extra $99 weekly. Taxes extra.
          </p>
          <div className="coin-panel">
                <div className="single-panel">
                  <p className="m-0">Buy regular job post $59</p>
                   <div className='buy-more'>
                    <div className='d-flex justify-center items-center gap-2'>
                    <span className='qty'>Qty: </span>
                    <select className='slt'>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                    </select>
                    </div>
                    <button className="btn btn-black"> Confirm </button>
                    </div>
                  
                </div>
            
          </div>
        </div>
      </div>
    </div>
  </>
  )
}

export default BuyMorePost